// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCzN9nQJGDoFsVD2uGkddEMTR089hMsxPs",
    authDomain: "what-word-b8cb0.firebaseapp.com",
    projectId: "what-word-b8cb0",
    storageBucket: "what-word-b8cb0.appspot.com",
    messagingSenderId: "649673566541",
    appId: "1:649673566541:web:adb32445b45bbaf92e2f04",
    measurementId: "G-Z2PVMYB5RY",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const functions = getFunctions(app);

export const auth = getAuth();
export const provider = new GoogleAuthProvider();
export const db = getFirestore();
