export var types;
(function (types) {
    let Mark;
    (function (Mark) {
        Mark["Correct"] = "c";
        Mark["Position"] = "p";
        Mark["Incorrect"] = "i";
        Mark["Unclassified"] = "u";
    })(Mark = types.Mark || (types.Mark = {}));
})(types || (types = {}));
